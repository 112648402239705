import React from 'react';

export default () => (
  <footer className='container-fluid black_more'>
    <div className='row m-10-hor'>
      <div className='col-md-12' centered>
        <div className='footer-col'>
          <div className='heading'>
            <h2 className='font-weight-bold '>About Us</h2>
          </div>
          <div className='content' centered>
             <p>VESBeck Solutions is run by Salome.  
               Salome has a BSc Computer Engineering degree and over 25 years experience in software development and known for her quality projects.</p>
          </div>
        </div>
      </div>
  {/*     <div className='col-md-2'>
        <div className='footer-col'>
         <div className='heading'>
            Usefull link
          </div>
          <div className='content'>
             <div className='link'>About Us</div>
             <div className='link'>Terms & Conditions</div>
             <div className='link'>Help Center</div>
             <div className='link'>Contact Us</div>
</div>
        </div>
      </div>
      <div className='col-md-2'>
        <div className='footer-col'>
          <div className='heading'>
            Features
          </div>
          <div className='content'>
            <div className='link'>About Us</div>
            <div className='link'>Workflow</div>
            <div className='link'>Contact Us</div>
          </div>
        </div>
      </div> */}
      {/* <div className='col-md-4'>
        <div className='footer-col'>
          <div className='heading'>
            Follow Us
          </div>
          <div className='content'>
            <p>To get updates and promotional info.</p>
            <div className='socialicon'>
              <i className="fa fa-facebook-f"></i>
            </div>
            <div className='socialicon'>
              <i className="fa fa-linkedin"></i>
            </div>
            <div className='socialicon'>
              <i className="fa fa-twitter"></i>
            </div>
            <div className='socialicon'>
              <i className="fa  fa-instagram"></i>
            </div>
          </div>
        </div>
      </div> */}
    </div>
    <div className='subfooter'>
      <div className='row m-10-hor'>
        <div className='col-md-6'>
          <div className='content'>© Copyright 2021 <span className='font-weight-bold'>VESBeck Solutions</span> All rights reserved.</div>
        </div>
        {/* <div className='col-md-6'>
          <div className='content-right'>
            Made with 
            <i className="fa fa-heart text-gradient"></i>
            By KandlSolutions
          </div>
        </div> */}
      </div>
    </div>
  </footer>
);
