import React from 'react';
import { Link } from '@reach/router';

export default () => (
  <section className='container-fluid'>
        <div className='row m-10-hor'>
          

          <div className='col-md-6'>
            <img src='./img/salome_.jpg' alt='imgbanner' className='w-100 border-radius'/>
            
          </div>
          <div className='col-md-6 centered'>
            <div>
              <div className="subheading">
              We are passionate 
              </div>
              <div className="heading">
                About Software Development
              </div>
              <p>
              The Business is small and run by Salome.  She prides herself in quality and that personal touch. 
              Salome has a BSc Computer Engineering degree and more than 25 years experience in Software Development for major banks, logistics and steel companies. 
              </p>
              <Link className='btn' to="/contact">
                <span>Contact Us</span>
              </Link>
            </div>
          </div>
          
        </div>
    </section>
);
