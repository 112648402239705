import React from 'react';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';

const content = [
  {
    title: "Software Development Solutions",
    description:
      "Salome has 25+ years experience as Software Engineer. Her passion is creating quality automated solutions for your business.",
        button: "Read More", 
  //  link: "//google.com",
    image: "./img/bg-1_new.jpg"
  },
  {
    title: "Flexible Solutions",
    description:
      "Our team either improve your current system, add-on additional solutions/support functions or create totally new customized solutions that best suits your need.",
    button: "Read More",
   // link: "//google.com",
    image: "./img/passion.jpg"
  },
  {
    title: "Affordable Solutions",
    description:
      "We strive to assist small businesses to evolve by creating automated solutions, saving you time and money.  We are flexible with payment arrangements.",
    button: "Read More",
   // link: "//google.com",
    image: "./img/bg-3.jpg"
  }
];

export default () => (
  <Slider className="slider-wrapper" autoplay={6000}>
            {content.map((item, index) => (
              <div
                key={index}
                className="slider-content"
                style={{ background: `url('${item.image}') no-repeat center center` }}
              >
                <div className="inner">
                  <h1>{item.title}</h1>
                  <p>{item.description}</p>
                  {/* <button onClick={()=> window.open(item.link, "_blank")}>
                    <span>
                      {item.button}
                    </span>
                  </button> */}
                </div>
              </div>
            ))}
        </Slider>
);
